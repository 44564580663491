import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spin"
}
const _hoisted_2 = { style: {"white-space":"pre-line"} }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        title: $setup.loading ? '...' : $setup.notification.title,
        parentPath: $setup.parentPath || '/notifications'
      }, null, 8, ["title", "parentPath"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          ($setup.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_spinner)
              ]))
            : (_openBlock(), _createBlock(_component_ion_grid, {
                key: 1,
                fixed: ""
              }, {
                default: _withCtx(() => [
                  ($setup.notification)
                    ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, { size: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, { class: "rounded-border" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_col, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_card_header, { class: "ion-text-center ion-margin-bottom" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_card_title, { color: "primary" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString($setup.notification.title), 1)
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_ion_card_subtitle, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString($setup.formatDate($setup.notification.scheduledToSendAt)), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_card_content, null, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", _hoisted_2, _toDisplayString($setup.notification.content), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      ($setup.notification.photo)
                                        ? (_openBlock(), _createBlock(_component_ion_col, {
                                            key: 0,
                                            "size-xs": "12",
                                            "size-md": "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("img", {
                                                src: $setup.notification.photoLink
                                              }, null, 8, _hoisted_3)
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}