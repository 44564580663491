
// Vue reactivity
import { computed, } from 'vue';

// icons
import { person, call, mail, refresh, clipboardOutline, checkmark, close,
          camera, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonButtons,
        IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonIcon, IonBackButton,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonThumbnail,
        IonList, IonListHeader, IonInput, IonTextarea, IonText, IonChip, IonBadge, IonImg,
        IonFabButton, loadingController, modalController } from '@ionic/vue';
import ImageSlides from "@/components/slides/ImageSlides.vue";

// composables
import { utils } from '@/composables/utils';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'NotificationDetailPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonButtons,
                IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonIcon, IonBackButton,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonThumbnail,
                IonList, IonListHeader, IonInput, IonTextarea, IonText, IonChip, IonBadge, IonImg,
                IonFabButton, ImageSlides, },
  setup() {
    const store = useStore();
    const { t, locale } = useI18n();
    const route = useRoute();
    const currId = route.params.id; // post ID

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingNotifications);
    const notification = computed(() => store.getters.getNotificationById(currId));
    
    // methods or filters
    const parentPath = route.params.parentPath;
    const { formatDate, presentToast } = utils();

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      person, call, mail, refresh, clipboardOutline, checkmark, close, camera,

      // variables
      locale, currId, parentPath, loading, notification,

      // methods
      t, formatDate,
    }
  },
}
